footer {
    background-color: var(--dark-clr);
    min-height: 90svh;
    color: var(--light-clr);
    
    
}

footer article{
    padding-inline: calc(64px + 1rem + 1vw);
}

#footer-section-title{
    font-size: var(--fs-5xl);
}

#footer-contact-info li{
    aspect-ratio: 1/1;
    height: calc(240px + 2rem);
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--shade-clr-2);
    border-radius: 12px;
    position: relative;
    
    
}

#footer-contact-info li button{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-clr);
    border-radius: 12px;
    border: none;
    padding: 1rem;
    color: var(--light-clr);
    letter-spacing: 0.03em !important;
    font-weight: 400 !important;
    position: relative;
    font-size: var(--fs-base) !important;
    transform: translate(-12px, -12px);
    transition: transform 0.3s ease-in-out;
    box-shadow: 8px 10px 10px 0px rgba(0,0,0,0.2);
}

#footer-contact-info li button:hover{
  
    transform: translate(-17px, -17px);
    
}

#footer-contact-info li:nth-of-type(1){
    background-color: var(--shade-clr-1);
} 


.social-link{
    color: var(--light-clr);
    transition: color 0.3s ease-in-out;
}

.social-link:hover, .social-link:focus{
    color: var(--main-clr);
}

#footer-map{
    box-shadow: -4px -10px 10px 0px rgba(0,0,0,0.1);
    height: 400px;
    filter: brightness(0.85);
}
@media (width < 576px) {
    #footer-map{
        height: 300px;
    }

    #footer-contact-info li{
        aspect-ratio: 0;
        height: 150px;
        width: 90%;
        
    }

    #footer-section-title{
        font-size: var(--fs-3xl);
    }

}

@media (width < 768px) {
    footer article{
        padding-inline: 1rem;
    }
}