.section-title{
    --_fs-page-title: clamp(8.77rem, 10.14vw + 7.9rem, 164px + 1vw);
    --_fs-page-title-sm: clamp(5.84rem,7.14vw + 5.1rem,86px + 1vw);
    
    writing-mode: vertical-rl;
    
    text-orientation: mixed;
    position: absolute;
    text-transform: uppercase;
    margin: 0;
    margin-block: auto;
    color: var(--faded-clr);
    font-size: var(--_fs-page-title);
    user-select: none;
    z-index: 0;
}

.title-left{
    left: 5px;
}

.title-right{
    right: 5px;
    transform: rotate(180deg);
}



@media (width < 576px) or (height < 900px) {
    .section-title{
        font-size: var(--_fs-page-title-sm) !important;
    }
}

@media (width < 400px) {
    .section-title{
        font-size: var(--_fs-page-title-sm) !important;
    }
}