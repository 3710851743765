@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --light-blue: #41b6da;
  --dark-blue: #2966b4;
  --light-purple: #cf51c9;
  --dark-purple: #b6097c;
  --grey-txt: #0000009a;
  --gradient-db-lp: linear-gradient(to left, var(--dark-blue), var(--light-purple)) 1;
  --bg-color: #1f1f1f;

  --light-clr: #fff;
  --main-clr: #81c3a3;
  --main-accent-1: #81C2C3;
  --main-accent-2: #81C382;
  --dark-clr: #222222;
  --faded-clr: #31313109;
  --shade-clr-1: #eee5dc;
  --shade-clr-2: #d9dbeb; 
  --shade-clr-3: #f7f7f7;
  --shade-clr-4: #dce4e0;

  --fs-xs: clamp(0.54rem, -0.12vw + 0.66rem, 0.57rem);
  --fs-sm: clamp(0.8rem, -0.08vw + 0.72rem, 0.75rem);
  --fs-base: clamp(1rem, 0vw + 0.9rem, 1rem);
  --fs-md: clamp(1.25rem, 0.13vw + 1.22rem, 1.33rem);
  --fs-lg: clamp(1.56rem, 0.34vw + 1.49rem, 1.77rem);
  --fs-xl: clamp(1.95rem, 0.67vw + 1.82rem, 2.35rem);
  --fs-2xl: clamp(2.14rem, 1.15vw + 1.45rem, 3.13rem);
  --fs-3xl: clamp(3.05rem, 1.85vw + 2.68rem, 45px + 1vw);
  --fs-4xl: clamp(3.81rem, 2.87vw + 3.24rem, 5.53rem);
  --fs-5xl: clamp(4.77rem, 4.32vw + 3.9rem, 64px + 1vw);
  --fs-6xl: clamp(3.35rem, 5.38vw + 1.68rem, 128px);
  --fs-7xl: clamp(6.45rem, 8.14vw + 5.26rem, 10.78rem);
  --fs-8xl: clamp(8.77rem, 10.14vw + 7.9rem, 164px + 1vw);

  --txtsize-title-min: 1.75rem;
  --txtsize-title-max: 10rem;

  --txtsize-h2-min: 1rem;
  --txtsize-h2-max: 7.5rem;

  --btn-border-size: clamp(2px, 2px + 0.1vw, 5vw);

  
  --pb-lg: calc(36px + 1rem);
  --pb-sm: calc(24px + 1rem);
}
@media (prefers-reduced-motion) {
  html{
    scroll-behavior: auto;
  
  }
}
@supports (-moz-appearance:none) {
  html {
    scroll-behavior: smooth !important;
    
  }
}
html{
  scroll-behavior: smooth;
  scrollbar-width: thin !important; 
  scrollbar-color: var(--main-clr) var(--light-clr) !important;
  --scrollbar-width-legacy: 8px !important;
}

body {
  margin: 0;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior:smooth;
  
}

main{
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Kanit', sans-serif !important;
}



h1 {
  font-size: var(--fs-5xl);
  font-weight: 700;
  color: var(--light-shade);

  letter-spacing: 0.02em;
}


h2 {
  font-size: var(--fs-3xl) ;
  font-weight: 600;
  color: var(--dark-shade);

  letter-spacing: 0.01em;
}

h3 {
  font-size: var(--fs-md);

  color: var(--dark-accent);
  font-weight: 400;
}

h4 {
  font-size: clamp(0.75rem, 0.75rem + 0.25vw, 2rem);

  color: var(--dark-accent);
  font-weight: 400;
}

h5 {

  font-size: clamp(1rem, 1rem + 0.1vw, 3rem);
  color: var(--dark-shade);
  font-weight: 400;
}


ul{
  list-style: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
button {
  font-family: 'Kanit', sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0.175rem !important;
}

#home{
  scroll-margin-top: 1000px;
}

#about, #skills{
  padding-block: var(--pb-lg) ;
  scroll-margin-top: 100px !important;
  
}

#projects{
  padding-block: var(--pb-lg) ;
  scroll-margin-top: 25px !important;
  
}