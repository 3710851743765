.skills-section {
    min-height: 100svh;
    background-color: var(--shade-clr-4);


}

.skills-container {
    /* border: 2px solid red; */
    height: 100%;
}

#skills-top-section {
    min-height: 30svh;
}

#skills-top-section h3 {
    font-size: var(--fs-3xl);
    text-align: end;
    margin-left: auto;
}

#skills-top-section h3 span {
    display: flex;
    align-items: center;

}

#skills-top-section h3 span div {
    background-color: var(--main-clr);
    height: 5px;
    border-radius: 5px;
}

#skills-bottom-section {
    gap: calc(12px + 0.25rem);
}

.skill-box {
    border: 2px solid var(--dark-clr);
    border-radius: 12px;
    
    min-height: calc(280px + 1rem);
    position: relative;
    overflow: hidden;
    padding-inline: calc(18px + 0.5rem);
    padding-top: calc(18px + 0.5rem);
    padding-bottom: calc(18px + 1.5rem);
    display: flex;

    align-items: center;
    flex-direction: column;
    z-index: 1;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.skill-box:nth-of-type(1){
    background-color: var(--shade-clr-1);
}
.skill-box:nth-of-type(2){
    background-color: var(--shade-clr-2);
}

.skill-box:nth-of-type(3){
    background-color: var(--shade-clr-3);
}
.skill-box-info {
    position: absolute;
    background-color: var(--dark-clr);
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    transform-origin: right;
    transform: scale(0.05, 0.4);

    transition: transform 0.3s ease-in-out;
}

.skill-box:hover {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.skill-box:hover .skill-box-info {
    transform: scale(1, 1);

}

.skill-box:hover .skill-box-info .skill-box-info-p {
    color: var(--light-clr);
    transition: color 0.5s ease-in-out;
}

.skill-box h4 {
    text-align: start;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;

    margin-bottom: calc(12px + 0.5rem);
}

.skill-box p {
    margin-bottom: 0;
    text-align: start;
    height: fit-content;
    font-size: var(--fs-base);
}

.skill-box-info-p {
    color: transparent;
    transition: color 0.2s ease-in-out;
}

.skill-description-container {
    display: flex;

}

#skills-divider {
    align-self: center;
    width: 100%;
    border-radius: 12px;
    margin-block: calc(64px + 1rem);
    height: 5px;
    background-color: var(--main-clr);
}

#skills-bullet-points{
    margin-bottom: 28px !important;
}

@media (width < 576px) {
    #skills-top-section h3 {
        font-size: var(--fs-2xl);
        text-align: start;
        margin-left: 0;
        width: 100%;
    }

    #skills-top-section {
        min-height: auto;
    }

    .skill-box {
        
        min-height: calc(200px + 1rem);
        padding-inline: calc(9px + 0.5rem);
        padding-top: calc(9px + 0.5rem);
        padding-bottom: calc(9px + 1.5rem);
    }
    #skills-top-section h3 {
        text-align: end;
    
    }

    #skills-top-section h3 span {
        text-align: end;
        color: var(--main-clr);
    }
    #skills-top-section h3 span {
        justify-content: flex-end;
    }

    .skill-box h4 {
        font-weight: 500;
        font-size: var(--fs-md);
    }

    .skill-box p {
        
        font-size: var(--fs-sm);
    }
}

@media (width < 400px) {
    #skills-top-section h3 {
        font-size: var(--fs-lg);
    }
    #skills-top-section h3 span div {
        height: 3px;
    }

    
}


@media (prefers-reduced-motion) {

    .skill-box, .skill-box-info{
        transition: none;
    }

    .skill-box:hover {
        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
    }

    .skill-box:hover .skill-box-info {
        transform: scale(1, 1);
    
    }
    
    .skill-box:hover .skill-box-info .skill-box-info-p {
        color: var(--light-clr);
    }
  
}