nav {
    --_navbar-height: 100px;
    background-color: var(--shade-clr-1);
    height: var(--_navbar-height);
    z-index: 4;
    transition: 
        background-color 0.3s ease-in-out,
        box-shadow 0.3s ease-in-out;
    
}

.navbar-changed{
    background-color: var(--light-clr);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

.nav-link {
    text-decoration: none;
    font-size: var(--fs-base);
    font-weight: 400 !important;
    cursor: pointer;
    color: var(--dark-clr);
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out !important;
    margin-bottom: 0;
    position: relative;
    text-transform: capitalize;
    letter-spacing: normal !important;
}
.lang-btn {
    aspect-ratio: 22/18;
    min-width: 24px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    margin-top: 2px;
    border-bottom: 3px solid transparent !important;
    transition: border 0.3s ease-in-out !important;
}

.lang-btn-active{
    border-bottom: 3px solid var(--main-clr) !important;
}

#btn-en{
    background-image: url('../../img/flag_US.svg');
}

#btn-dk{
    background-image: url('../../img/flag_DK.svg');
}
.active{
    color: var(--main-clr) !important;
}

nav div ul {
    position: relative;
}

.logo{
   
    width: calc(48px + 2vw);
    cursor: pointer;
}

.logo svg{
    height: 100%;
    width: 100%;
}

.logo svg g{
    transition: fill 0.3s ease-in-out;
}

.logo:hover svg g{
    fill: var(--main-clr) !important;
}

.hamburger-react{
    margin-top: 1px;
    
}

.hamburger-react:focus{
    border: 1px solid var(--main-clr);
}

#nav-links-container-sm{
    width: 100%;
    background-color: var(--light-clr);
    position: absolute;
    z-index: 0;
    left: 0;
   transition: transform 0.3s ease-in-out;
   transform-origin: top;
}
.nav-collapsed{
    transform: scaleY(0);
}
.nav-expanded{
    transform: scaleY(1);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
}

#nav-links-container-sm ul {
    width: 100%;
}

#nav-links-container-sm ul li{
    min-height: 66px;
   
    position: relative;
}


#nav-links-container-sm ul li button{
    width: 100%;
    min-height: 66px;
    height: 100%;
    text-align: start;
}

#nav-links-container-sm ul li .lang-btn{
   width: 33px;
    min-height: 33px;
    margin-top: 4px;
    
}

@media (width > 576px) {
    .nav-link:not(.lang-btn):before{
        position: absolute;
        content: '';
        bottom: -2px;
        width: 0%;
        height: 2px;
        background-color: var(--main-clr);
        transition: width 0.3s ease-in-out;
    }
    
    
    .nav-link:hover{
        color: var(--main-clr) !important;
    }
    
    .nav-link:hover::before{
        width: 100%;
    }
}

@media (width < 576px) {
    nav{
        height: 66px;
        background-color: var(--light-clr);
        
    }

    .nav-link p{
        width: fit-content;
    }

    .logo{
        height: 20px;
    }
    .logo a{
        height: 100%;
        width: 100%;
        display: flex;
    }
}