
.App {
  text-align: center;
  background-color: transparent;
  background-color: #fafafa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.content-container{
  display: flex;
  padding: 0;
  flex-direction: column;
  padding-top: 100px;
  width: 100%;
  position: relative;
}

#floating-action-btn{
  position: fixed;
  width: 60px;
  height: 60px;
  background-color: var(--main-clr);
  bottom: 30px;
  right: 30px;
  transition: transform 0.3s ease-in-out;
}

#floating-action-btn svg{
  fill: var(--light-clr);
  
}

.fab-visible{
  
  transform: scale(1);
}

.fab-hidden{
  
  transform: scale(0);
}



@media (width < 576px) {
  #floating-action-btn{
    
    bottom: 10px;
    right: 10px;
  }
}



.App-link {
  color: #61dafb;
}

