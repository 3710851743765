/* DESCRIPTION------------------------- */
.hero-section {
    --_icon-height: 60px;
    --_icon-text-offset: calc(var(--_icon-height)/2);
    min-height: 100svh;
    position: relative;
    background-color: var(--shade-clr-1);
    
}
.welcome-box {
    background-color: var(--main-clr);
    width: 3rem;
    height: 0.25rem;
}

.welcome-tag {
    font-size: var(--fs-sm);
    font-weight: 600;
    margin: 0;
    color: var(--main-clr);
    text-transform: uppercase;
    letter-spacing: 0.375em;
    text-align: start;
    line-height: 0.9;
}



.description {
    width: 100%;
}

#description-box{
    position: relative;
}


.description-title {
    margin: 0;
    text-align: start;
    color: var(--dark-clr);
    text-transform: uppercase;
    font-size: var(--fs-6xl);
    font-weight: 700;
    padding: 0;
    line-height: 0.9;
    
}

#outlined-text{
    color: transparent;
    -webkit-text-stroke-width:  2px;
    -webkit-text-stroke-color: var(--dark-clr);
}




.description-tag {
    margin: 0;
    margin-top: 1.2rem;
    margin-bottom: 2.4rem;


    text-align: start;

}





/* BUTTONS------------------------- */

.contact-btn-container {
    
    
    background-color: transparent;

   
    column-gap: 1rem;
    row-gap: 1rem;
    

}


.quick-btn {

    font-size: var(--fs-base) !important;
    min-height: 70px;
    
    border: var(--btn-border-size) solid var(--main-clr) !important;
    border-radius: 6px !important;
    z-index: 1;
    position: relative !important;
    outline: 0;
    overflow: hidden;
    aspect-ratio: 2/0.5;
}

.fill:before {
    content: "";
    position: absolute;
    background: var(--main-clr);
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    transition: top 200ms ease-in;
    color: var(--dark-clr) !important;

}

.fill {
    color: var(--dark-clr) !important;
}

.fill:hover, .fill:focus {
    color: var(--light-clr) !important;
}



.fill:hover:before, .fill:focus:before {
    top: 0;
    border: none !important;
    


}

.slide {
    background-color: transparent !important;
    border: var(--btn-border-size) solid var(--dark-clr) !important;

}


.slide:after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: attr(data-content1);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    transition: all 400ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
    color: var(--dark-clr) !important;
}

.slide:before {
    display: flex;
    align-items: center;
    justify-content: center;
    content: attr(data-content2);
    height: 100%;
    width: 100%;
    position: absolute;
    left: -100%;
    opacity: 0;
    transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550);
    color: var(--light-clr) !important;
}

.slide:hover, .slide:focus {
    background: var(--main-clr) !important;
    border: var(--btn-border-size) solid transparent !important;
    color: var(--light-clr) !important;
}

.slide:hover:before, .slide:focus:before {
    left: 0;
    opacity: 1;
    color: var(--light-clr) !important;
}

.slide:hover:after, .slide:focus:after {
    left: 100%;
    opacity: 0;
}
/* SKILLS BANNER----------------------- */

#description-skills-container{
   position: absolute;
    height: 80px;
    /* border: 1px solid red; */
    bottom: -100px;
   
}

.skill-container{
    
    height: var(--_icon-height);
    aspect-ratio: 1/1;
    position: relative;
    
}

.skill-icon-box{
    z-index: 2;
    position: relative;
}

.skill-icon{
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    border-radius: 6px;
    transition: transform 0.3s ease-in-out;
}

.skill-container:nth-of-type(1) .skill-icon{
    background-image: url('../../../img/logo_js.svg'); 
    background-color: #f7e025;
    
}


.skill-container:nth-of-type(2) .skill-icon{
    background-image: url('../../../img/logo_ts.svg'); 
}

.skill-container:nth-of-type(3) .skill-icon{
    background-image: url('../../../img/logo_html.svg'); 
    
}

.skill-container:nth-of-type(4) .skill-icon{
    background-image: url('../../../img/logo_css.svg'); 
    
}

.skill-container:nth-of-type(5) .skill-icon{
    background-image: url('../../../img/logo_react.svg'); 
    background-color: #444d5b;
   
}

.skill-container:nth-of-type(6) .skill-icon{
    background-image: url('../../../img/logo_nextjs.svg'); 
}

.skill-icon-decoration{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
    background-color: var(--shade-clr-2);
    z-index: 0;
}

#skill-icon-decoration-css, #skill-icon-decoration-html{
    background-color: transparent;
    background-image: url('../../../img/logo_css_html_bg.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

#skill-icon-decoration-next{
    border-radius: 50%;
}

.skill-icon-name{
    position: absolute;
    font-size: var(--fs-base);
    width: calc(var(--_icon-height)*2);
    left: calc(var(--_icon-text-offset) - 2*var(--_icon-text-offset));
    
    /* left: calc(var(--_icon-text-offset) - 2*var(--_icon-text-offset));
    right: calc(var(--_icon-text-offset) - 5*var(--_icon-text-offset)); */
    transform: translateY(-30px);
    text-align: center;
    color: transparent;
    transition: color 0.2s ease-in-out, transform 0.4s ease-in-out;
}

.skill-container:hover .skill-icon{
    transform: translate(-6px, -6px);
}

.skill-container:hover .skill-icon-name{
    color: var(--dark-clr);
    transform: translateY(3px);
    transition: color 0.6s ease-in-out, transform 0.3s ease-in-out;
}








/* SCROLL BANNER----------------------- */

.scroll-banner {
    background: transparent;

    min-height: 3rem;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;

    align-items: center;
    justify-content: center;
    row-gap: 0;
    padding-bottom: 2rem;
    padding-top: 2rem;
    color: var(--light-clr);
    font-family: 'Poppins', sans-serif;
    

}

.scroll-link {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    padding-bottom: 0rem;

    min-height: 3rem;
    transition: all 500ms;

}

.mouse {
    width: 25px;
    height: 40px;
    border: 2px solid var(--dark-clr);
    border-radius: 42%;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
}

.mouse:focus{
    border-color: var(--main-clr);
}

.mouse::before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 7px;
    left: 48%;
    transform: translateX(-50%);
    background-color: var(--main-clr);
    border-radius: 50%;
    opacity: 1;
    animation: wheel 1.3s infinite;
    -webkit-animation: wheel 1.3s infinite;
}

@keyframes wheel {
    to {
        opacity: 0;
        top: 27px
    }
}

@-webkit-keyframes wheel {
    to {
        opacity: 0;
        top: 27px
    }
}

@media(orientation: portrait) and (width > 576px){
    #description-skills-container{
       
         bottom: -200px;
        
     }
}

@media (width < 992px) {
    .quick-btn {
        min-height: 50px;
        font-size: var(--fs-sm) !important;
        aspect-ratio: 2.5/0.5;
    }
}


@media (width < 576px) {
    #home{
        padding-block: calc(66px + 1rem);
    }
    .welcome-tag{
        font-size: var(--fs-xs);
        
    }

    .description-tag{
        font-size: var(--fs-sm);
        
    }

    .description-title{
        letter-spacing: 0.04em !important;
        font-size: var(--fs-3xl);
        
    }

    #outlined-text{
        letter-spacing: 0.04em !important;
    }

    #description-skills-container{
        height: fit-content;
        bottom: -75px;
    }

    .skill-container{
        --_icon-height: 36px;
    }

    .skill-icon-name{
        left: -21px;
        right: -21px;
        font-size: var(--fs-sm);
    }


    .quick-btn {
        min-height: 50px;
        font-size: var(--fs-sm) !important;
        aspect-ratio: 2/0.5;
        width: 45%;
    }
}


@media (hover: none), (prefers-reduced-motion) {
    .skill-container:hover .skill-icon{
        transform: none;
    }
    
    .skill-container:hover .skill-icon-name{
        
        transform: none;
        transition: none;
    }
}

@media (prefers-reduced-motion) {
    .mouse::before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 7px;
        left: 48%;
        transform: translateX(-50%);
        background-color: var(--main-clr);
        border-radius: 50%;
        opacity: 1;
        animation: none;
        -webkit-animation: none;
    }
}