.bullet-point{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    min-height: 70px;
    overflow: hidden;
    background-color: var(--light-clr);
    transition: box-shadow 0.3s ease-in-out;
    padding-inline-end: 0.5rem;
    min-width: fit-content;
}

.bullet-point:hover{
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}


.bullet-point p {
    text-align: start;
    padding-inline: 1rem;
}

.bullet-point div {
    background-color: var(--shade-clr-3);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.bullet-point div svg {
    fill: var(--main-clr);

}

.bullet-point div::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 200%;
    background-color: var(--light-clr);

    bottom: -50%;
    right: -83%;
    transform: rotate(18deg);
}



@media (width < 576px) {
    .about-bullet{
        flex-grow: 0;
    }
    .skills-bullet{
        width: 100%;
    }
}

@media (width > 576px) {
    .about-bullet{
        flex-grow: 1;
    }
    .skills-bullet{
        flex-grow: 1;
        width: calc(50% - 1rem);
    }
}

@media (width > 768px) {
    
}



@media (width > 992px) {
   
    .about-bullet{
        width: 25%;
    }
    .skills-bullet{
        width: calc(25% - 1rem);
        flex-grow: 1;
        max-width: calc(33% - 1rem);
    }
}