.about-section {
    min-height: fit-content;
    background-color: var(--shade-clr-2);
}

.about-container {
    min-height: 80svh;
}

.about-container div h3 {
    text-align: start;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 0;
    font-size: var(--fs-3xl) !important;
}

#title-text-transform {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    column-gap: calc(10px + 0.5rem);
    color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--dark-clr);
}

#title-text-dash {
    border-radius: 5px;
    height: 5px;
    background-color: var(--main-clr);
}

#personal-img {
    position: relative;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    aspect-ratio: 1.3/1.7;
    z-index: 3;
    background-color: var(--shade-clr-1);
    border-radius: 12px;
}

#img-box-style {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--main-clr);
    right: 0;
    top: 0;
    border-radius: 12px;
    z-index: 2;
}



#about-description-container {
    margin-top: calc(36px + 1rem);
}







#about-description {
    text-align: start;
    margin: 0;
}

#contact-me-btn-container {
    margin-top: calc(36px + 1rem);
}

#contact-me-btn {
    border: 2px solid var(--main-clr);
    color: var(--light-clr);
    text-transform: uppercase;
    background-color: transparent;
    overflow: hidden;
    position: relative;
    transition: color 0.3s ease-in-out;
    font-size: var(--fs-md);
    padding: 1rem;
}

#contact-me-btn p {
    margin: 0;
    position: relative;
}

#contact-me-btn::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--main-clr);
    transition: transform 0.3s ease-in-out;
}

#contact-me-btn:hover, #contact-me-btn:focus {
    color: var(--dark-clr);
}

#contact-me-btn:hover::before, #contact-me-btn:focus::before {
    transform: translateY(100%);
}

#about-description-arrow-container {

    position: relative;
}

#about-description-arrow {
    position: absolute;
    left: -150%;
    top: -7.5rem;
    background-image: url('../../../img/arrow.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 150%;
    height: 110%;

}

#about-me-heading {
    font-size: var(--fs-md);
    color: var(--main-clr);
    text-align: start;
}



@media (width < 1200px) {
    #about-description {
        text-align: justify;
        font-size: var(--fs-base);
        font-weight: 300;
    }
}

@media (width < 1200px) and (orientation: portrait) {}

@media (width < 576px) {


    .about-container div h3 {
        font-size: var(--fs-2xl) !important;
        text-align: center;
    }

    #contact-me-btn {
        margin-inline: auto;
    }

    #about-description {
        text-align: start;

    }

    #title-text-transform {

        color: var(--main-clr);
        -webkit-text-stroke-width: 0px;
        -webkit-text-stroke-color: none;

    }

    .about-bullet {
        width: calc(50% - 0.5rem);
    }

}

@media (width < 450px) {
    .about-container div h3 {
        font-size: var(--fs-lg) !important;
    }

    .about-bullet {
        width: 100%;

    }
}


@media (prefers-reduced-motion) {

    #contact-me-btn:hover, #contact-me-btn:focus {
        color: var(--light-clr);
    }
    
    #contact-me-btn:hover::before, #contact-me-btn:focus::before {
        transform: none;
    }
  
}